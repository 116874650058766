<!--
* @Page: 医生介绍
* @name: 张阳帅
* @Date: 2024-09-30 16:11:34
* @LastEditTime: 2024-10-09 15:18:02
* @Description: 
* @FilePath: \szlsnk-user-mp\src\views\gjc\hosp\priceList.vue
-->
<template>
  <div class="doc_detail_page">
    <div class="page_back" />
    <div class="page_top">
      <div class="top_back" />
      <div class="top_title">
        <img class="title_img" :src="docDitail.ystx" alt="">
        <div class="title_back">                
          {{ docDitail.ysxm }}
          <a class="back_title">{{ docDitail.zcmc }}</a>
        </div>
      </div>
    </div>
    <div class="page_body">  
      <div class="body_info">
        <van-divider>
          <a class="info_text">个人介绍</a>
        </van-divider>
        <div class="info_cont" v-html="docDitail.ysjs" />
      </div>
      <div class="body_info">
        <van-divider>
          <a class="info_text">擅长</a>
        </van-divider>
        <div class="info_cont" v-html="docDitail.yssc" />
      </div>
      <div class="body_info">
        <van-divider>
          <a class="info_text">接诊科室</a>
        </van-divider>
        <van-tag plain type="primary">
          {{ docDitail.jzksmc }}
        </van-tag>
      </div>
    </div>
  </div>
</template>
<script>
    import { gjcModel } from '@/api/gjcApi'
    export default {
        name:'DocDetail',
        data(){
            return{
                docDitail:{}
            }
        },
        mounted(){
            this.getDocDetailData()
        },
        methods:{
            getDocDetailData(){
                gjcModel.getDocDetailData(this.$route.query.id).then((result) => {
                    this.docDitail = result.data
                })
            }
        }
    }
</script>
<style scoped lang='less'>
.doc_detail_page{
    .page_back{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 260px;
        z-index: -2;
        background-color: #1e80ff;
    }
    .page_top{
        background-color: transparent;
        .top_back{
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            margin-top: 170px;
            z-index: -1;
            background-color: white;
            border-radius: 15px 15px 0 0;
            height: 100px;
        }
        .top_title{
            display: flex;
            margin: 80px 20px 0 32px;
            height: 160px;
            .title_img{
                width: 160px;
                height: 160px;
                border-radius: 50%;
                border: white solid 10px;
            }
            .title_back{
                margin-left: 20px;
                font-size: 36px;
                font-weight: bold;
                color:white;
            }
            .back_title{
                margin-left: 20px;
                font-size: 28px;
            }
        }
    }
    .page_body{
        background-color: white;
        margin: 0 20px;
        padding-bottom: 50px;
        // height: 400px;
        .info_text{
            font-size: 30px;
            color:#333;
        }
        .info_cont{
            font-size: 30px;
            color:#333;
        }
    }
}
</style>